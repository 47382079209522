class Breadcrumb extends lm.Composant {
  constructor(id) {
    super(id);
    // Init code
    const isScrolable = document.querySelector('.m-breadcrumb__container');
    if (isScrolable) {
      const isScrolableId = isScrolable.id;
      if (isScrolableId) {
        const slider = document.querySelector('#scrollableBreadcrumb');
        let isDown = false;
        let startX;
        let scrollLeft;
        slider.scrollLeft = 1000;
        slider.addEventListener('mousedown', e => {
          isDown = true;
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
          isDown = false;
        });
        slider.addEventListener('mouseup', () => {
          isDown = false;
        });
        slider.addEventListener('mousemove', e => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 3; //scroll-fast
          slider.scrollLeft = scrollLeft - walk;
        });
      }
    }
  }
}
let BreadcrumbModule = new Breadcrumb("breadcrumb");
lm.sentry.configureScope(function (scope) {
  scope.setTag("kobi.composant", "breadcrumb");
});